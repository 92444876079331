import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Auth0Provider } from "@auth0/auth0-react";

import Router from "../Router";
import FullscreenLoadingIndicator from "../../visual/shared/FullscreenLoadingIndicator";

export default function AuthenticatorWrapper() {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      redirectUri={
        window.location.protocol +
        "//" +
        window.location.host +
        (window.location.pathname === "/settings" ? "/settings" : "")
      }
      cacheLocation="localstorage"
      useRefreshTokens
      audience={process.env.REACT_APP_AUTH0_AUDIENCE!}
    >
      <Authenticator />
    </Auth0Provider>
  );
}

const Authenticator = withAuthenticationRequired(Router, {
  onRedirecting: () => <FullscreenLoadingIndicator />,
  loginOptions: { screen_hint: window.location.pathname === "/signup" ? "signup" : undefined },
});
