import { StrictMode } from "react";
import { BrowserRouter } from "react-router-dom";
import { useMemo } from "react";
import { init } from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import mixpanel from "mixpanel-browser";

import Authenticator from "../Authenticator";

export default function InitialiserWrapper() {
  return (
    <StrictMode>
      <BrowserRouter>
        <Initialiser />
      </BrowserRouter>
    </StrictMode>
  );
}

function Initialiser() {
  useMemo(() => {
    init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [
        new BrowserTracing({
          tracingOrigins: [/^\//, process.env.REACT_APP_SENTRY_TRACING_ORIGIN!],
        }),
      ],
      tracesSampleRate: 1.0,
      environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
      release: process.env.REACT_APP_SENTRY_RELEASE,
      tunnel: process.env.REACT_APP_SENTRY_TUNNEL,
    });

    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      mixpanel.init("DUMMY");
    } else {
      mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY!, {
        ignore_dnt: true,
        api_host: process.env.REACT_APP_MIXPANEL_API_HOST,
      });
    }
  }, []);

  return <Authenticator />;
}
