import { captureException, setUser } from "@sentry/react";

export function extractBackendErrorMessage(error: any) {
  if (error?.response?.data) {
    if (error.response.data.errors) {
      if ("full_messages" in error.response.data.errors) {
        if (error.response.data.errors.full_messages[0]) {
          if (error.response.data.errors.full_messages[0].endsWith(".")) {
            return error.response.data.errors.full_messages[0];
          } else {
            return error.response.data.errors.full_messages[0] + ".";
          }
        }
      }
    } else if (error.response.data.error) {
      return error.response.data.error;
    }
  }

  return "Apologies, we've run into a problem. Please try again later.";
}

export function reportError(error: any) {
  console.error(error);

  if (error.message !== "Network Error") {
    captureException(error);
  }
}

export function identifyUser(user: { email?: string; sub?: string }) {
  setUser({ email: user.email, id: user.sub });
}
