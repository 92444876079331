import Logo from "../../../assets/svg/logo.svg";

export default function Header() {
  return (
    <div className="max-w-7xl mx-auto py-7 flex justify-center">
      <a href="https://getlemon.app" target="_blank" rel="noreferrer" className="flex items-center space-x-5 py-2 px-2">
        <img className="h-7" src={Logo} alt="Lemon Logo" />
      </a>
    </div>
  );
}
