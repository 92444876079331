import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect, useMemo } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Settings from "../../visual/Settings";
import Onboarding from "../../visual/Onboarding";
import Onboarded from "../../visual/Onboarded";
import NotFound from "../../visual/NotFound";
import FullscreenLoadingIndicator from "../../visual/shared/FullscreenLoadingIndicator";
import FullscreenErrorMessage from "../../visual/shared/FullscreenErrorMessage";
import { useApi } from "../../../hooks/use-api.hook";
import { identifyUser as identifyUserSentry } from "../../../helpers/error.helpers";
import { identifyUser as identifyUserMixpanel } from "../../../helpers/analytics.helpers";
import { SHOW_USER } from "../../../constants/endpoints.constants";

export default function Router() {
  const { user } = useAuth0();

  useMemo(() => {
    identifyUserSentry(user!);
    identifyUserMixpanel(user!);
  }, [user]);

  return (
    <Routes>
      <Route
        path="/settings"
        element={
          <OnboardingRedirecter key="/settings" target="/settings">
            <Settings />
          </OnboardingRedirecter>
        }
      />
      <Route
        path="/onboarding"
        element={
          <OnboardingRedirecter key="/onboarding" target="/onboarding">
            <Onboarding />
          </OnboardingRedirecter>
        }
      />
      <Route
        path="/onboarded"
        element={
          <OnboardingRedirecter key="/onboarded" target="/onboarded">
            <Onboarded />
          </OnboardingRedirecter>
        }
      />
      <Route path="/" element={<Navigate to="/onboarded" replace />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

function OnboardingRedirecter({ children, target }: { children: JSX.Element; target: string }) {
  const {
    authError: showUserApiCallAuthError,
    error: showUserApiCallError,
    hasCompleted: showUserApiCallHasCompleted,
    response: showUserApiCallResponse,
  }: {
    authError: any;
    error: any;
    hasCompleted: boolean;
    response: { user: { active: boolean } } | undefined;
  } = useApi({ endpoint: SHOW_USER });

  const [status, setStatus] = useState("LOADING");

  useEffect(() => {
    if (!showUserApiCallHasCompleted) {
      return;
    }

    if (showUserApiCallError || showUserApiCallAuthError) {
      // @ts-ignore
      if (showUserApiCallError?.response?.status === 404) {
        setStatus("ONBOARDING_NOT_COMPLETED");
      } else {
        setStatus("ERROR");
      }
    } else {
      // @ts-ignore
      if (showUserApiCallResponse?.user?.active) {
        setStatus("ONBOARDING_COMPLETED");
      } else {
        setStatus("ONBOARDING_NOT_COMPLETED");
      }
    }
  }, [showUserApiCallHasCompleted]);

  switch (status) {
    case "LOADING":
      return <FullscreenLoadingIndicator />;
    case "ERROR":
      return <FullscreenErrorMessage />;
    case "ONBOARDING_COMPLETED":
      if (target === "/onboarding") {
        return <Navigate to="/onboarded" replace />;
      } else {
        return children;
      }
    case "ONBOARDING_NOT_COMPLETED":
      if (target === "/onboarding") {
        return children;
      } else {
        return <Navigate to="/onboarding" replace />;
      }
    default:
      return null;
  }
}
