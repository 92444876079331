import mixpanel from "mixpanel-browser";

export function identifyUser(user: { email?: string; sub?: string }) {
  if (process.env.NODE_ENV === "production") {
    mixpanel.identify(user.sub);
    mixpanel.people.set({ $email: user.email });
  }
}

export function setUserName(user: { first_name: string; last_name: string }) {
  if (process.env.NODE_ENV === "production") {
    mixpanel.people.set({ $first_name: user.first_name, $last_name: user.last_name });
  }
}
