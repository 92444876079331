import { useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

import { Endpoint } from "../constants/endpoints.constants";
import { substituteValuesIntoPlaceholders } from "../helpers/url.helpers";

export function useApiSynchronously() {
  const { getAccessTokenSilently } = useAuth0();

  const send = useCallback<
    ({
      endpoint,
      options,
    }: {
      endpoint: Endpoint;
      options?: { params?: any; data?: any; urlPlaceholderValues?: [{ name: string; value: string }] };
    }) => Promise<any>
  >(
    ({ endpoint, options }) => {
      return getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE! })
        .then((accessToken) => {
          const abort = axios.CancelToken.source();
          const abortRequestTimeoutId = setTimeout(() => abort.cancel("Network Error"), 10000);

          const axiosConfiguration = Object.fromEntries(
            Object.entries({
              url: substituteValuesIntoPlaceholders(endpoint.url, options?.urlPlaceholderValues),
              method: endpoint.method,
              headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
              params: options?.params,
              data: options?.data,
              timeout: 10000,
              cancelToken: abort.token,
            }).filter(([_, value]) => value !== undefined)
          );

          return axios(axiosConfiguration)
            .then((response) => {
              clearTimeout(abortRequestTimeoutId);
              return response;
            })
            .catch((error) => {
              clearTimeout(abortRequestTimeoutId);
              return Promise.reject(error);
            });
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    [getAccessTokenSilently]
  );

  return { send };
}
