import { useEffect } from "react";
import lottie from "lottie-web";

import loadingAnimation from "../../../assets/lottie/loading.json";

export default function FullscreenLoadingIndicator() {
  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#loading-indicator-container")!,
      animationData: loadingAnimation,
    });
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
      <div className="m-auto w-1/3 h-1/3" id="loading-indicator-container" />
    </div>
  );
}
