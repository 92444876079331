import { useAuth0 } from "@auth0/auth0-react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { object, string } from "yup";
import { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";

import Header from "../shared/Header";
import Footer from "../shared/Footer";
import ErrorModal from "../shared/ErrorModal";
import { useApiSynchronously } from "../../../hooks/use-api-synchronously.hook";
import {
  INDEX_BUSINESSES,
  SHOW_USER,
  CREATE_USER,
  CREATE_BUSINESS,
  UPDATE_USER,
} from "../../../constants/endpoints.constants";
import FullscreenLoadingIndicator from "../../visual/shared/FullscreenLoadingIndicator";
import FullscreenErrorMessage from "../../visual/shared/FullscreenErrorMessage";
import { reportError } from "../../../helpers/error.helpers";
import { setUserName } from "../../../helpers/analytics.helpers";
import { useApi } from "../../../hooks/use-api.hook";

export default function Onboarding() {
  const { user } = useAuth0();
  const { send } = useApiSynchronously();
  let navigate = useNavigate();

  const [status, setStatus] = useState("LOADING");
  const [hasUserCompletedOnboarding, setHasUserCompletedOnboarding] = useState(false);
  const [doesUserHaveAccessibleBusinesses, setDoesUserHaveAccessibleBusinesses] = useState(false);

  const {
    authError: showUserApiCallAuthError,
    error: showUserApiCallError,
    hasCompleted: showUserApiCallHasCompleted,
    response: showUserApiCallResponse,
  }: {
    authError: any;
    error: any;
    hasCompleted: boolean;
    response: { user: { first_name: string } } | undefined;
  } = useApi({ endpoint: SHOW_USER });

  const {
    authError: indexBusinessesApiCallAuthError,
    error: indexBusinessesApiCallError,
    hasCompleted: indexBusinessesApiCallHasCompleted,
    response: indexBusinessesApiCallResponse,
  }: {
    authError: any;
    error: any;
    hasCompleted: boolean;
    response:
      | {
          accessible_businesses: [
            {
              public_id: string;
            }
          ];
        }
      | undefined;
  } = useApi({ endpoint: INDEX_BUSINESSES });

  useEffect(() => {
    if (!indexBusinessesApiCallHasCompleted || !showUserApiCallHasCompleted) {
      return;
    }

    if (
      indexBusinessesApiCallError ||
      indexBusinessesApiCallAuthError ||
      showUserApiCallError ||
      showUserApiCallAuthError
    ) {
      if (indexBusinessesApiCallAuthError || showUserApiCallAuthError) {
        setStatus("ERROR");
        return;
      }

      // @ts-ignore
      if (indexBusinessesApiCallError?.response?.status === 403 && showUserApiCallError?.response?.status === 404) {
        setHasUserCompletedOnboarding(false);
        setDoesUserHaveAccessibleBusinesses(false);

        setStatus("LOADED");
      } else {
        setStatus("ERROR");
      }
    } else {
      // @ts-ignore
      setHasUserCompletedOnboarding(!!showUserApiCallResponse?.user?.first_name);

      // @ts-ignore
      setDoesUserHaveAccessibleBusinesses(indexBusinessesApiCallResponse?.accessible_businesses?.length > 0);

      setStatus("LOADED");
    }
  }, [indexBusinessesApiCallHasCompleted, showUserApiCallHasCompleted]);

  const validationSchema = useMemo(() => {
    if (status !== "LOADED") {
      return null;
    }

    if (doesUserHaveAccessibleBusinesses) {
      return object().shape({
        firstName: string().required("Please enter your first name."),
        lastName: string().required("Please enter your last name."),
      });
    } else {
      if (hasUserCompletedOnboarding) {
        return object().shape({
          businessName: string().required("Please enter the name of your business."),
        });
      } else {
        return object().shape({
          firstName: string().required("Please enter your first name."),
          lastName: string().required("Please enter your last name."),
          businessName: string().required("Please enter the name of your business."),
        });
      }
    }
  }, [status, doesUserHaveAccessibleBusinesses, hasUserCompletedOnboarding]);

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  switch (status) {
    case "LOADING":
      return <FullscreenLoadingIndicator />;
    case "ERROR":
      return <FullscreenErrorMessage />;
    case "LOADED":
      return (
        <>
          <div className="bg-white">
            <Header />

            <div className="bg-lemon-100 py-12">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="bg-white rounded-xl">
                  <div className="grid grid-cols-1 lg:grid-cols-4 divide-y lg:divide-y-0 sm:divide-x divide-gray-100">
                    <div className="py-10 px-6 sm:px-10 xl:p-12 lg:col-span-2">
                      {doesUserHaveAccessibleBusinesses ? (
                        <h2 className="text-3xl font-semibold text-lemon tracking-tight">Join your team today</h2>
                      ) : (
                        <>
                          <h3 className="text-xl font-semibold text-gray-800 tracking-tight">Start using lemon for</h3>
                          <h2 className="text-3xl font-semibold text-lemon tracking-tight mt-2">£0 GBP today</h2>

                          <h3 className="text-lg font-medium text-gray-600 tracking-tight mt-7">
                            £19 GBP per month per location after your one-month trial.{" "}
                            <span className="font-semibold text-gray-800">Cancel anytime.</span>
                          </h3>
                        </>
                      )}

                      <div className="mt-8 space-y-2">
                        <div className="flex items-center space-x-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-lemon flex-shrink-0"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={4}
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                          </svg>
                          <h3 className="text-lg font-medium text-gray-600 tracking-tight">
                            All-in-one HACCP records: temp checks, food items, deliveries and checklists
                          </h3>
                        </div>

                        <div className="flex items-center space-x-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-lemon flex-shrink-0"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={4}
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                          </svg>
                          <h3 className="text-lg font-medium text-gray-600 tracking-tight">
                            EHO-compliant digital records, exportable any time
                          </h3>
                        </div>

                        <div className="flex items-center space-x-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-lemon flex-shrink-0"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={4}
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                          </svg>
                          <h3 className="text-lg font-medium text-gray-600 tracking-tight">
                            Invite unlimited team members to collaborate
                          </h3>
                        </div>

                        <div className="flex items-center space-x-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-lemon flex-shrink-0"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={4}
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                          </svg>
                          <h3 className="text-lg font-medium text-gray-600 tracking-tight">
                            Works offline and when connectivity is poor
                          </h3>
                        </div>

                        <div className="flex items-center space-x-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-lemon flex-shrink-0"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={4}
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                          </svg>
                          <h3 className="text-lg font-medium text-gray-600 tracking-tight">
                            Get reminders to keep your records up to date
                          </h3>
                        </div>
                      </div>
                    </div>

                    <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                      <h3 className="text-center text-xl font-semibold text-gray-800 tracking-tight">
                        Set up your account
                      </h3>

                      <Formik
                        initialValues={{
                          firstName: "",
                          lastName: "",
                          businessName: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                          setSubmitting(true);

                          if (hasUserCompletedOnboarding) {
                            send({
                              endpoint: CREATE_BUSINESS,
                              options: { data: { business: { name: values.businessName } } },
                            })
                              .then(() => {
                                navigate("/onboarded", { replace: true });

                                // @ts-ignore
                                if (showUserApiCallResponse?.user?.active) {
                                  mixpanel.track("User Created Trialing Business");
                                } else {
                                  mixpanel.track("Inactive User Created Trialing Business");
                                }
                              })
                              .catch((error) => {
                                setIsErrorModalOpen(true);
                                setSubmitting(false);

                                reportError(error);
                              });
                          } else {
                            send({
                              endpoint: CREATE_USER,
                              options: {
                                data: {
                                  user: {
                                    first_name: values.firstName,
                                    last_name: values.lastName,
                                    email: user!.email,
                                  },
                                },
                              },
                            })
                              .then((response) => {
                                if (doesUserHaveAccessibleBusinesses) {
                                  navigate("/onboarded", { replace: true });
                                  mixpanel.track("User Set Up Account");
                                } else {
                                  send({
                                    endpoint: CREATE_BUSINESS,
                                    options: { data: { business: { name: values.businessName } } },
                                  })
                                    .then(() => {
                                      navigate("/onboarded", { replace: true });
                                      mixpanel.track("User Created Trialing Business");
                                    })
                                    .catch((error) => {
                                      setIsErrorModalOpen(true);
                                      setSubmitting(false);

                                      reportError(error);
                                    });
                                }

                                setUserName(response.data.user);
                              })
                              .catch((error) => {
                                if (error?.response?.status === 409) {
                                  send({
                                    endpoint: UPDATE_USER,
                                    options: {
                                      data: {
                                        user: {
                                          first_name: values.firstName,
                                          last_name: values.lastName,
                                        },
                                      },
                                    },
                                  })
                                    .then((response) => {
                                      if (doesUserHaveAccessibleBusinesses) {
                                        navigate("/onboarded", { replace: true });
                                        mixpanel.track("Invited User Set Up Account");
                                      } else {
                                        send({
                                          endpoint: CREATE_BUSINESS,
                                          options: { data: { business: { name: values.businessName } } },
                                        })
                                          .then(() => {
                                            navigate("/onboarded", { replace: true });
                                            mixpanel.track("Invited User Created Trialing Business");
                                          })
                                          .catch((error) => {
                                            setIsErrorModalOpen(true);
                                            setSubmitting(false);

                                            reportError(error);
                                          });
                                      }

                                      setUserName(response.data.user);
                                    })
                                    .catch((error) => {
                                      setIsErrorModalOpen(true);
                                      setSubmitting(false);

                                      reportError(error);
                                    });
                                } else {
                                  setIsErrorModalOpen(true);
                                  setSubmitting(false);

                                  reportError(error);
                                }
                              });
                          }
                        }}
                      >
                        {({ isSubmitting, isValid }) => (
                          <Form className="space-y-6 mt-6">
                            {hasUserCompletedOnboarding || (
                              <>
                                <div>
                                  <label
                                    htmlFor="first-name"
                                    className="text-sm font-medium text-gray-800 tracking-tight"
                                  >
                                    First name
                                  </label>
                                  <div className="mt-1">
                                    <Field
                                      type="text"
                                      name="firstName"
                                      id="first-name"
                                      autoComplete="given-name"
                                      className="py-3 px-4 block w-full shadow-sm text-gray-800 focus:ring-lemon focus:border-lemon border-gray-200 rounded-md"
                                    />
                                  </div>
                                  <ErrorMessage
                                    name="firstName"
                                    component="div"
                                    className="mt-2 text-sm font-medium text-red-500"
                                  />
                                </div>

                                <div>
                                  <label
                                    htmlFor="last-name"
                                    className="text-sm font-medium text-gray-800 tracking-tight"
                                  >
                                    Last name
                                  </label>
                                  <div className="mt-1">
                                    <Field
                                      type="text"
                                      name="lastName"
                                      id="last-name"
                                      autoComplete="family-name"
                                      className="py-3 px-4 block w-full shadow-sm text-gray-800 focus:ring-lemon focus:border-lemon border-gray-200 rounded-md"
                                    />
                                  </div>
                                  <ErrorMessage
                                    name="lastName"
                                    component="div"
                                    className="mt-2 text-sm font-medium text-red-500"
                                  />
                                </div>
                              </>
                            )}

                            {doesUserHaveAccessibleBusinesses || (
                              <div>
                                <label
                                  htmlFor="business-name"
                                  className="text-sm font-medium text-gray-800 tracking-tight"
                                >
                                  Business name
                                </label>
                                <div className="mt-1">
                                  <Field
                                    type="text"
                                    id="business-name"
                                    name="businessName"
                                    className="py-3 px-4 block w-full shadow-sm text-gray-800 focus:ring-lemon focus:border-lemon border-gray-200 rounded-md"
                                  />
                                </div>
                                <ErrorMessage
                                  name="businessName"
                                  component="div"
                                  className="mt-2 text-sm font-medium text-red-500"
                                />
                              </div>
                            )}

                            <p className="text-gray-600 text-sm">
                              By signing up, you agree to our{" "}
                              <a
                                href="https://getlemon.app/policies"
                                target="_blank"
                                rel="noreferrer"
                                className="text-lemon font-medium"
                              >
                                Terms and Conditions and Privacy Policy
                              </a>
                              .
                            </p>

                            <div className="sm:col-span-2 sm:flex sm:justify-end">
                              <button
                                type="submit"
                                disabled={isSubmitting || !isValid}
                                className={`w-full sm:w-fit mt-1 inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-full shadow-sm text-base font-semibold text-white tracking-tight bg-lemon focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lemon ${
                                  isSubmitting ? "animate-pulse" : "hover:bg-lemon-600"
                                } ${!isValid ? "opacity-50" : ""}`}
                              >
                                {doesUserHaveAccessibleBusinesses ? "Join your team" : "Start one-month trial"}
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </div>

          <ErrorModal isOpen={isErrorModalOpen} setIsOpen={setIsErrorModalOpen} />
        </>
      );
    default:
      return null;
  }
}
