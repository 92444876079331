import { useState, useEffect, useCallback, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

import { extractBackendErrorMessage, reportError } from "../helpers/error.helpers";
import { Endpoint } from "../constants/endpoints.constants";
import { substituteValuesIntoPlaceholders } from "../helpers/url.helpers";

export function useApi({
  endpoint,
  options = undefined,
}: {
  endpoint: Endpoint;
  options?: { params?: any; data?: any; urlPlaceholderValues?: [{ name: string; value: string }] };
}) {
  const { getAccessTokenSilently } = useAuth0();

  const [isLoading, setIsLoading] = useState(false);
  const [hasCompletedCurrentRequestSuccessfully, setHasCompletedCurrentRequestSuccessfully] = useState(false);
  const [hasCompleted, setHasCompleted] = useState(false);
  const concurrentRequests = useRef(0);

  const [response, setResponse] = useState();

  const [error, setError] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [authError, setAuthError] = useState();
  const [authErrorMessage, setAuthErrorMessage] = useState("");

  const [refreshIndex, setRefreshIndex] = useState(0);
  const refresh = useCallback(() => {
    setRefreshIndex(refreshIndex + 1);
  }, [refreshIndex]);

  useEffect(() => {
    setIsLoading(true);
    concurrentRequests.current = concurrentRequests.current + 1;

    getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE! })
      .then((accessToken) => {
        const abort = axios.CancelToken.source();
        const abortRequestTimeoutId = setTimeout(() => abort.cancel("Network Error"), 10000);

        const axiosConfiguration = Object.fromEntries(
          Object.entries({
            url: substituteValuesIntoPlaceholders(endpoint.url, options?.urlPlaceholderValues),
            method: endpoint.method,
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            params: options?.params,
            data: options?.data,
            timeout: 10000,
            cancelToken: abort.token,
          }).filter(([_, value]) => value !== undefined)
        );

        axios(axiosConfiguration)
          .then((response) => {
            clearTimeout(abortRequestTimeoutId);

            setResponse(response.data);
            setHasCompletedCurrentRequestSuccessfully(true);
          })
          .catch((error) => {
            clearTimeout(abortRequestTimeoutId);

            setError(error);
            setErrorMessage(extractBackendErrorMessage(error));

            setHasCompletedCurrentRequestSuccessfully(false);

            reportError(error);
          })
          .then(() => {
            setHasCompleted(true);

            concurrentRequests.current = concurrentRequests.current - 1;
            if (concurrentRequests.current === 0) {
              setIsLoading(false);
            }
          });
      })
      .catch((error) => {
        setAuthError(error);
        setAuthErrorMessage("Apologies, we ran into an error. Please try again later.");

        setHasCompletedCurrentRequestSuccessfully(false);
        setHasCompleted(true);

        concurrentRequests.current = concurrentRequests.current - 1;
        if (concurrentRequests.current === 0) {
          setIsLoading(false);
        }

        reportError(error);
      });
  }, [refreshIndex]);

  return {
    isLoading,
    hasCompletedCurrentRequestSuccessfully,
    hasCompleted,
    response,
    authError,
    authErrorMessage,
    error,
    errorMessage,
    refresh,
  };
}
