import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

import Header from "../shared/Header";
import Footer from "../shared/Footer";
import Onelinkto from "../../../assets/svg/onelinkto.svg";
import AppStore from "../../../assets/svg/app_store.svg";
import PlayStore from "../../../assets/svg/play_store.svg";

export default function Onboarded() {
  const { logout } = useAuth0();
  let navigate = useNavigate();

  return (
    <div className="bg-white">
      <Header />

      <div className="bg-lemon-100 py-12">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-xl px-4">
            <div className="pt-20 pb-16 border-b border-gray-100">
              <h1 className="text-3xl sm:text-5xl tracking-tight font-semibold text-gray-700 text-center">Welcome!</h1>
              <h1 className="text-xl mt-3 tracking-tight font-medium text-gray-500 text-center">
                Scan the QR code with your phone to install the app.
              </h1>
            </div>

            <div className="py-12 border-b border-gray-100 flex flex-col items-center justify-center">
              <img className="h-64" src={Onelinkto} alt="App QR Code" />
              <div className="flex items-center space-x-8 mt-8">
                <a
                  href="https://apps.apple.com/us/app/lemon-haccp-for-restaurants/id1624370305"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="h-12" src={AppStore} alt="App Store link" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=app.getlemon.app"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="h-12" src={PlayStore} alt="Play Store link" />
                </a>
              </div>
            </div>

            <div className="py-12 flex flex-col flex-col-reverse sm:flex-row justify-center items-center sm:space-x-5">
              <button
                onClick={() => logout()}
                className="flex items-center space-x-3 rounded-full bg-red-100 hover:bg-red-200 py-2 pl-5 pr-4"
              >
                <p className="tracking-tight text-red-600 font-semibold">Sign out</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-red-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2.5}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                  />
                </svg>
              </button>

              <button
                onClick={() => navigate("/settings")}
                className="mb-4 sm:mb-0 flex items-center space-x-3 rounded-full bg-lemon-200 hover:bg-lemon-300 py-2 pl-5 pr-4"
              >
                <p className="tracking-tight text-lemon-800 font-semibold">Go to settings</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-lemon-800"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2.5}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  />
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
