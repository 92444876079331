import { Method } from "axios";

export interface Endpoint {
  url: string;
  method: Method;
}

// Base URLs

const BASE_URL = process.env.REACT_APP_API_BASE_URL!;
const API_URL = `${BASE_URL}/account/v1`;

// Authenticated endpoints

export const CREATE_USER: Endpoint = { url: `${API_URL}/user`, method: "post" }; // users#create
export const SHOW_USER: Endpoint = { url: `${API_URL}/user`, method: "get" }; // users#show
export const UPDATE_USER: Endpoint = { url: `${API_URL}/user`, method: "patch" }; // users#update

export const CREATE_USER_DEACTIVATION: Endpoint = { url: `${API_URL}/user/deactivation`, method: "post" }; // deactivations#create

export const SHOW_SUBSCRIPTION: Endpoint = { url: `${API_URL}/user/subscription`, method: "get" }; // subscriptions#show

export const CREATE_CHECKOUT_SESSION: Endpoint = {
  url: `${API_URL}/user/subscription/checkout_sessions`,
  method: "post",
}; // checkout_sessions#create

export const CREATE_CUSTOMER_PORTAL_SESSION: Endpoint = {
  url: `${API_URL}/user/subscription/customer_portal_sessions`,
  method: "post",
}; // customer_portal_session#create

export const CREATE_BUSINESS: Endpoint = { url: `${API_URL}/businesses`, method: "post" }; // businesses#create
export const INDEX_BUSINESSES: Endpoint = { url: `${API_URL}/businesses`, method: "get" }; // businesses#index
export const DESTROY_BUSINESS: Endpoint = {
  url: `${API_URL}/businesses/[id]`,
  method: "delete",
}; // businesses#destroy

export const DESTROY_BUSINESS_MEMBERSHIP: Endpoint = {
  url: `${API_URL}/businesses/[id]/memberships/placeholder`,
  method: "delete",
}; // memberships#destroy
