export function substituteValuesIntoPlaceholders(
  url: string,
  placeholderValues: { name: string; value: string }[] | undefined
) {
  if (!placeholderValues) {
    return url;
  }

  placeholderValues.forEach((placeholder) => {
    url = url.replace(`[${placeholder.name}]`, placeholder.value);
  });

  return url;
}
