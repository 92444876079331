import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { object, string } from "yup";
import { ExclamationIcon, PlusIcon } from "@heroicons/react/outline";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import mixpanel from "mixpanel-browser";

import { useApi } from "../../../hooks/use-api.hook";
import Header from "../shared/Header";
import Footer from "../shared/Footer";
import FullscreenLoadingIndicator from "../../visual/shared/FullscreenLoadingIndicator";
import FullscreenErrorMessage from "../../visual/shared/FullscreenErrorMessage";
import ErrorModal from "../shared/ErrorModal";
import { useApiSynchronously } from "../../../hooks/use-api-synchronously.hook";
import {
  DESTROY_BUSINESS,
  DESTROY_BUSINESS_MEMBERSHIP,
  CREATE_BUSINESS,
  CREATE_USER_DEACTIVATION,
  SHOW_USER,
  SHOW_SUBSCRIPTION,
  INDEX_BUSINESSES,
  CREATE_CHECKOUT_SESSION,
  CREATE_CUSTOMER_PORTAL_SESSION,
} from "../../../constants/endpoints.constants";
import { reportError } from "../../../helpers/error.helpers";

const validationSchema = object().shape({
  name: string().required("Please enter the business name."),
});

export default function Settings() {
  const {
    response: showUserApiCallResponse,
    hasCompleted: showUserApiCallHasCompleted,
    hasCompletedCurrentRequestSuccessfully: showUserApiCallHasCompletedCurrentRequestSuccessfully,
  }: {
    response: { user: { first_name: string; last_name: string; created_at: string } } | undefined;
    hasCompleted: boolean;
    hasCompletedCurrentRequestSuccessfully: boolean;
  } = useApi({ endpoint: SHOW_USER });

  const {
    response: showSubscriptionApiCallResponse,
    isLoading: showSubscriptionApiCallIsLoading,
    hasCompleted: showSubscriptionApiCallHasCompleted,
    hasCompletedCurrentRequestSuccessfully: showSubscriptionApiCallHasCompletedCurrentRequestSuccessfully,
    refresh: showSubscriptionApiCallRefresh,
  }: {
    response:
      | {
          subscription: {
            first_payment_at: string;
            next_payment_at: string;
            total: number;
            stripe_status: string;
            enabled: boolean;
          };
        }
      | undefined;
    isLoading: boolean;
    hasCompleted: boolean;
    hasCompletedCurrentRequestSuccessfully: boolean;
    refresh: () => void;
  } = useApi({ endpoint: SHOW_SUBSCRIPTION });

  const {
    response: indexBusinessesApiCallResponse,
    hasCompleted: indexBusinessesApiCallHasCompleted,
    hasCompletedCurrentRequestSuccessfully: indexBusinessesApiCallHasCompletedCurrentRequestSuccessfully,
    refresh: indexBusinessesApiCallRefresh,
  }: {
    response:
      | {
          owned_businesses: [
            {
              name: string;
              public_id: string;
              created_at: string;
            }
          ];
          unowned_accessible_businesses: [
            {
              name: string;
              public_id: string;
              owner: { first_name: number; last_name: string };
            }
          ];
        }
      | undefined;
    hasCompleted: boolean;
    hasCompletedCurrentRequestSuccessfully: boolean;
    refresh: () => void;
  } = useApi({ endpoint: INDEX_BUSINESSES });

  const [status, setStatus] = useState("LOADING");

  const pollingForLiveSubscriptionCounter = useRef(0);

  useEffect(() => {
    if (
      !showUserApiCallHasCompleted ||
      !indexBusinessesApiCallHasCompleted ||
      showSubscriptionApiCallIsLoading ||
      status === "LOADED"
    ) {
      return;
    }

    if (
      showUserApiCallHasCompletedCurrentRequestSuccessfully &&
      indexBusinessesApiCallHasCompletedCurrentRequestSuccessfully &&
      showSubscriptionApiCallHasCompletedCurrentRequestSuccessfully
    ) {
      if (showSubscriptionApiCallResponse!.subscription.enabled) {
        const params = new URLSearchParams(window.location.search);

        if (params.has("checkout_session_success")) {
          if (params.get("checkout_session_success") === "true") {
            if (
              showSubscriptionApiCallResponse!.subscription.stripe_status === "LIVE" ||
              showSubscriptionApiCallResponse!.subscription.stripe_status === "ERRORING"
            ) {
              setStatus("LOADED");
            } else {
              if (pollingForLiveSubscriptionCounter.current > 5) {
                setStatus("LOADED");

                setTimeout(() => {
                  setIsAddingPaymentMethodFailedModalOpen(true);
                }, 1000);
              } else {
                pollingForLiveSubscriptionCounter.current += 1;

                setTimeout(() => {
                  showSubscriptionApiCallRefresh();
                }, 1000);
              }
            }
          } else {
            setStatus("LOADED");

            setTimeout(() => {
              setIsAddingPaymentMethodFailedModalOpen(true);
            }, 1000);
          }
        } else {
          setStatus("LOADED");
        }
      } else {
        setStatus("LOADED");
      }
    } else {
      setStatus("ERROR");
    }
  }, [
    showUserApiCallHasCompleted,
    indexBusinessesApiCallHasCompleted,
    showSubscriptionApiCallHasCompleted,
    showSubscriptionApiCallIsLoading,
  ]);

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const [isAddingPaymentMethodFailedModalOpen, setIsAddingPaymentMethodFailedModalOpen] = useState(false);
  const addingPaymentMethodFailedModalCancelButtonRef = useRef(null);

  const [isBusinessDeletionWarningModalOpen, setIsBusinessDeletionWarningModalOpen] = useState(false);
  const businessDeletionWarningModalCancelButtonRef = useRef(null);

  const [idOfBusinessBeingDeleted, setIdOfBusinessBeingDeleted] = useState("");
  const [isBusinessBeingDeleted, setIsBusinessBeingDeleted] = useState(false);

  const [isBusinessLeavingWarningModalOpen, setIsBusinessLeavingWarningModalOpen] = useState(false);
  const businessLeavingWarningModalCancelButtonRef = useRef(null);

  const [idOfBusinessBeingLeft, setIdOfBusinessBeingLeft] = useState("");
  const [isBusinessBeingLeft, setIsBusinessBeingLeft] = useState(false);

  const [isAddBusinessModalOpen, setIsAddBusinessModalOpen] = useState(false);
  const addBusinessModalCancelButtonRef = useRef(null);

  const [isAccountClosureWarningModalOpen, setIsAccountClosureWarningModalOpen] = useState(false);
  const accountClosureWarningModalCancelButtonRef = useRef(null);

  const [isAccountBeingClosed, setIsAccountBeingClosed] = useState(false);

  const [isCheckoutSessionBeingCreated, setIsCheckoutSessionBeingCreated] = useState(false);
  const [isCustomerPortalSessionBeingCreated, setIsCustomerPortalSessionBeingCreated] = useState(false);

  const { user, logout } = useAuth0();
  const { send } = useApiSynchronously();

  switch (status) {
    case "LOADING":
      return <FullscreenLoadingIndicator />;
    case "ERROR":
      return <FullscreenErrorMessage />;
    case "LOADED":
      return (
        <>
          <div className="bg-white">
            <Header />

            <div className="bg-lemon-100 py-12">
              <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="bg-white rounded-xl px-4">
                  <div className="pt-20 pb-16 border-b border-gray-100">
                    <h1 className="text-3xl sm:text-5xl tracking-tight font-semibold text-gray-700 text-center truncate">
                      {showUserApiCallResponse!.user.first_name + " " + showUserApiCallResponse!.user.last_name}
                    </h1>
                    <h1 className="text-xl mt-1 sm:mt-3 tracking-tight font-medium text-gray-500 text-center">
                      Account & Settings
                    </h1>
                  </div>

                  <div className="px-4 sm:px-12 py-12 border-b border-gray-100">
                    <div className="pb-6 border-b border-gray-100 flex items-center justify-between">
                      <h1 className="text-xl tracking-tight font-medium text-gray-500 truncate">Your Businesses</h1>
                      <button
                        onClick={() => setIsAddBusinessModalOpen(true)}
                        className="flex items-center space-x-2 rounded-full bg-lemon-200 hover:bg-lemon-300 py-2 pl-5 pr-4"
                      >
                        <p className="tracking-tight text-lemon-800 font-semibold text-sm">Add</p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 text-lemon-800"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                        </svg>
                      </button>
                    </div>

                    {indexBusinessesApiCallResponse!.owned_businesses.length > 0 ? (
                      <div className="space-y-6 divide-y divide-gray-100">
                        {indexBusinessesApiCallResponse!.owned_businesses.map((business) => {
                          return (
                            <div className="pt-6 flex justify-between space-x-6 items-center" key={business.public_id}>
                              <div>
                                <h3 className="text-lg font-medium text-gray-700 tracking-tight leading-tight">
                                  {business.name}
                                </h3>
                                <h3 className="text-sm text-gray-500 italic tracking-tight leading-tight mt-1">
                                  Added on {moment(business.created_at).format("Do MMM")}
                                </h3>
                              </div>
                              <div className="items-center flex space-x-2">
                                <button
                                  onClick={() => {
                                    setIdOfBusinessBeingDeleted(business.public_id);
                                    setIsBusinessDeletionWarningModalOpen(true);
                                  }}
                                  className="flex items-center space-x-2 rounded-full bg-red-100 hover:bg-red-200 py-2 pl-5 pr-4"
                                >
                                  <p className="tracking-tight text-red-600 font-semibold text-sm">Delete</p>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4 text-red-600"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          );
                        })}

                        <div className="pt-1 space-y-6 divide-y divide-gray-100">
                          <div className="pt-6 pb-1 border-t border-gray-100 flex justify-between items-center">
                            <div>
                              <h3 className="text-lg font-medium text-gray-700 tracking-tight">Your monthly total:</h3>
                              {moment().isBefore(
                                moment(showSubscriptionApiCallResponse!.subscription.first_payment_at),
                                "day"
                              ) ? (
                                <h3 className="text-sm text-gray-500 italic tracking-tight">
                                  Starting from{" "}
                                  {moment(showSubscriptionApiCallResponse!.subscription.next_payment_at).format(
                                    "Do MMM"
                                  )}
                                </h3>
                              ) : (
                                <>
                                  {showSubscriptionApiCallResponse!.subscription.stripe_status === "LIVE" ||
                                  showSubscriptionApiCallResponse!.subscription.stripe_status === "ERRORING" ? (
                                    <h3 className="text-sm text-gray-500 italic tracking-tight">
                                      Next payment on{" "}
                                      {moment(showSubscriptionApiCallResponse!.subscription.next_payment_at).format(
                                        "Do MMM"
                                      )}
                                    </h3>
                                  ) : (
                                    <h3 className="text-sm text-gray-500 italic tracking-tight">
                                      Trial expired on{" "}
                                      {moment(showSubscriptionApiCallResponse!.subscription.first_payment_at).format(
                                        "Do MMM"
                                      )}
                                    </h3>
                                  )}
                                </>
                              )}
                            </div>
                            <h3 className="text-xl font-medium text-gray-700 tracking-tight">
                              £
                              {showSubscriptionApiCallResponse!.subscription.enabled
                                ? (+showSubscriptionApiCallResponse!.subscription.total).toFixed(2)
                                : "0.00"}
                            </h3>
                          </div>

                          {showSubscriptionApiCallResponse!.subscription.stripe_status !== "LIVE" &&
                          showSubscriptionApiCallResponse!.subscription.enabled ? (
                            <>
                              {moment().isBefore(
                                moment(showSubscriptionApiCallResponse!.subscription.first_payment_at),
                                "day"
                              ) ? (
                                <>
                                  {moment(showSubscriptionApiCallResponse!.subscription.first_payment_at).diff(
                                    moment(),
                                    "days"
                                  ) < 7 ? (
                                    <div className="pt-8 pb-2">
                                      <p className="text-red-400 italic font-medium tracking-tight">
                                        Your trial ends in{" "}
                                        {moment(showSubscriptionApiCallResponse!.subscription.first_payment_at).diff(
                                          moment(),
                                          "days"
                                        ) + 1}{" "}
                                        day
                                        {moment(showSubscriptionApiCallResponse!.subscription.first_payment_at).diff(
                                          moment(),
                                          "days"
                                        ) > 0
                                          ? "s"
                                          : ""}
                                        . Please add a payment method below to continue using lemon.
                                      </p>
                                    </div>
                                  ) : null}
                                </>
                              ) : (
                                <>
                                  {showSubscriptionApiCallResponse!.subscription.stripe_status === "ERRORING" ? (
                                    <div className="pt-8 pb-2">
                                      <p className="text-red-400 italic font-medium tracking-tight">
                                        We couldn't collect payment. Please update your payment method below and then
                                        get in touch with us{" "}
                                        <a href="https://getlemon.app/contact" className="underline">
                                          here
                                        </a>{" "}
                                        so we can unlock your account.
                                      </p>
                                    </div>
                                  ) : (
                                    <div className="pt-8 pb-2">
                                      <p className="text-red-400 italic font-medium tracking-tight">
                                        Your trial is over. Please add a payment method below to continue using lemon.
                                      </p>
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          ) : null}

                          <div className="pt-6 flex justify-end">
                            {(showSubscriptionApiCallResponse!.subscription.stripe_status === "LIVE" ||
                              showSubscriptionApiCallResponse!.subscription.stripe_status === "ERRORING") &&
                            showSubscriptionApiCallResponse!.subscription.enabled ? (
                              <button
                                className="flex items-center space-x-2 rounded-full bg-lemon-200 hover:bg-lemon-300 py-2 pl-5 pr-4"
                                onClick={() => {
                                  mixpanel.track("User Started Changing Payment Method From Web App");
                                  setIsCustomerPortalSessionBeingCreated(true);

                                  send({
                                    endpoint: CREATE_CUSTOMER_PORTAL_SESSION,
                                  })
                                    .then((response) => {
                                      window.location.href = response.data.customer_portal_session.url;
                                    })
                                    .catch((error) => {
                                      setTimeout(() => {
                                        setIsCustomerPortalSessionBeingCreated(false);
                                        setIsErrorModalOpen(true);
                                      }, 1000);

                                      reportError(error);
                                    });
                                }}
                              >
                                <p className="tracking-tight text-lemon-800 font-semibold text-sm">
                                  {isCustomerPortalSessionBeingCreated ? "Loading page..." : "Change payment method"}
                                </p>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-4 w-4 text-lemon-800"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                  />
                                </svg>
                              </button>
                            ) : (
                              <button
                                className={`flex items-center space-x-2 rounded-full bg-lemon-200 py-2 pl-5 pr-4 ${
                                  showSubscriptionApiCallResponse!.subscription.enabled
                                    ? "hover:bg-lemon-300"
                                    : "opacity-50"
                                }`}
                                title={
                                  !showSubscriptionApiCallResponse!.subscription.enabled
                                    ? "You are on our free plan."
                                    : ""
                                }
                                disabled={!showSubscriptionApiCallResponse!.subscription.enabled}
                                onClick={() => {
                                  mixpanel.track("User Started Adding Payment Method From Web App");
                                  setIsCheckoutSessionBeingCreated(true);

                                  send({
                                    endpoint: CREATE_CHECKOUT_SESSION,
                                  })
                                    .then((response) => {
                                      window.location.href = response.data.checkout_session.url;
                                    })
                                    .catch((error) => {
                                      setTimeout(() => {
                                        setIsCheckoutSessionBeingCreated(false);
                                        setIsErrorModalOpen(true);
                                      }, 1000);

                                      reportError(error);
                                    });
                                }}
                              >
                                <p className="tracking-tight text-lemon-800 font-semibold text-sm">
                                  {isCheckoutSessionBeingCreated ? "Loading page..." : "Add payment method"}
                                </p>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-4 w-4 text-lemon-800"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                >
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                                </svg>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <h3 className="text-gray-500 tracking-tight font-medium text-sm mt-6 mb-2">
                        You do not own any businesses.
                      </h3>
                    )}
                  </div>

                  <div className="px-4 sm:px-12 py-12 border-b border-gray-100">
                    <div className="space-y-6 pb-6 border-b border-gray-100 divide-y divide-gray-100">
                      <h1 className="text-xl tracking-tight font-medium text-gray-500 truncate">Other Businesses</h1>
                    </div>

                    {indexBusinessesApiCallResponse!.unowned_accessible_businesses.length > 0 ? (
                      <div className="space-y-6 divide-y divide-gray-100">
                        {indexBusinessesApiCallResponse!.unowned_accessible_businesses.map((business) => {
                          return (
                            <div className="pt-6 flex justify-between space-x-6 items-center" key={business.public_id}>
                              <div>
                                <h3 className="text-lg font-medium text-gray-700 tracking-tight leading-tight">
                                  {business.name}
                                </h3>
                                <h3 className="text-sm text-gray-500 italic tracking-tight leading-tight mt-1">
                                  Invited by {business.owner.first_name + " " + business.owner.last_name}
                                </h3>
                              </div>
                              <div className="items-center flex space-x-2">
                                <button
                                  onClick={() => {
                                    setIdOfBusinessBeingLeft(business.public_id);
                                    setIsBusinessLeavingWarningModalOpen(true);
                                  }}
                                  className="flex items-center space-x-2 rounded-full bg-red-100 hover:bg-red-200 py-2 pl-5 pr-4"
                                >
                                  <p className="tracking-tight text-red-600 font-semibold text-sm">Leave</p>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4 text-red-600"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <h3 className="text-gray-500 tracking-tight font-medium text-sm mt-6 mb-2">
                        You have not been invited to any other businesses.
                      </h3>
                    )}
                  </div>

                  <div className="p-12 flex flex-col flex-col-reverse sm:flex-row justify-center items-center sm:space-x-5">
                    <button
                      onClick={() => setIsAccountClosureWarningModalOpen(true)}
                      className="flex items-center space-x-3 rounded-full bg-red-100 hover:bg-red-200 py-2 pl-5 pr-4"
                    >
                      <p className="tracking-tight text-red-600 font-semibold">Close account</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 text-red-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2.5}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </button>
                    <button
                      onClick={() => {
                        logout();
                        mixpanel.reset();
                      }}
                      className="mb-4 sm:mb-0 flex items-center space-x-3 rounded-full bg-red-100 hover:bg-red-200 py-2 pl-5 pr-4"
                    >
                      <p className="tracking-tight text-red-600 font-semibold">Sign out</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 text-red-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2.5}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </div>

          <ErrorModal isOpen={isErrorModalOpen} setIsOpen={setIsErrorModalOpen} />

          <Transition.Root show={isAddingPaymentMethodFailedModalOpen} as={Fragment}>
            <Dialog
              as="div"
              className="fixed z-10 inset-0 overflow-y-auto"
              initialFocus={addingPaymentMethodFailedModalCancelButtonRef}
              onClose={setIsAddingPaymentMethodFailedModalOpen}
            >
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                  &#8203;
                </span>

                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 py-5 sm:p-6">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                        </div>

                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                            Something went wrong!
                          </Dialog.Title>

                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              It seems like something went wrong while you tried to add your payment method. You can try
                              again any time.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row-reverse">
                      <a
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-lemon text-base font-medium text-white hover:bg-lemon-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lemon sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setIsAddingPaymentMethodFailedModalOpen(false)}
                        href="https://getlemon.app/contact"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Contact us
                      </a>

                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setIsAddingPaymentMethodFailedModalOpen(false)}
                        ref={addingPaymentMethodFailedModalCancelButtonRef}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          <Transition.Root show={isBusinessLeavingWarningModalOpen} as={Fragment}>
            <Dialog
              as="div"
              className="fixed z-10 inset-0 overflow-y-auto"
              initialFocus={businessLeavingWarningModalCancelButtonRef}
              onClose={setIsBusinessLeavingWarningModalOpen}
            >
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                  &#8203;
                </span>

                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 py-5 sm:p-6">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                        </div>

                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                            Are you sure?
                          </Dialog.Title>

                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              You will lose access to this business and all its food safety records and exports
                              irreversibly.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-400 text-base font-medium text-white hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => {
                          setIsBusinessBeingLeft(true);

                          send({
                            endpoint: DESTROY_BUSINESS_MEMBERSHIP,
                            options: {
                              urlPlaceholderValues: [{ name: "id", value: idOfBusinessBeingLeft }],
                              params: { email: user!.email },
                            },
                          })
                            .then(() => {
                              indexBusinessesApiCallRefresh();

                              setIsBusinessLeavingWarningModalOpen(false);
                              setTimeout(() => setIsBusinessBeingLeft(false), 1000);

                              mixpanel.track("User Left Business");
                            })
                            .catch((error) => {
                              indexBusinessesApiCallRefresh();
                              setIsBusinessLeavingWarningModalOpen(false);

                              setTimeout(() => {
                                setIsBusinessBeingLeft(false);
                                setIsErrorModalOpen(true);
                              }, 1000);

                              reportError(error);
                            });
                        }}
                      >
                        {isBusinessBeingLeft ? "Leaving..." : "Leave"}
                      </button>

                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setIsBusinessLeavingWarningModalOpen(false)}
                        ref={businessLeavingWarningModalCancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          <Transition.Root show={isBusinessDeletionWarningModalOpen} as={Fragment}>
            <Dialog
              as="div"
              className="fixed z-10 inset-0 overflow-y-auto"
              initialFocus={businessDeletionWarningModalCancelButtonRef}
              onClose={setIsBusinessDeletionWarningModalOpen}
            >
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                  &#8203;
                </span>

                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 py-5 sm:p-6">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                        </div>

                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                            Are you sure?
                          </Dialog.Title>

                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              This will delete all food safety records and exports associated with this business
                              irreversibly.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-400 text-base font-medium text-white hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => {
                          setIsBusinessBeingDeleted(true);

                          send({
                            endpoint: DESTROY_BUSINESS,
                            options: { urlPlaceholderValues: [{ name: "id", value: idOfBusinessBeingDeleted }] },
                          })
                            .then(() => {
                              indexBusinessesApiCallRefresh();
                              showSubscriptionApiCallRefresh();

                              setIsBusinessDeletionWarningModalOpen(false);
                              setTimeout(() => setIsBusinessBeingDeleted(false), 1000);

                              mixpanel.track("User Deleted Business");
                            })
                            .catch((error) => {
                              indexBusinessesApiCallRefresh();
                              showSubscriptionApiCallRefresh();

                              setIsBusinessDeletionWarningModalOpen(false);

                              setTimeout(() => {
                                setIsBusinessBeingDeleted(false);
                                setIsErrorModalOpen(true);
                              }, 1000);

                              reportError(error);
                            });
                        }}
                      >
                        {isBusinessBeingDeleted ? "Deleting..." : "Delete"}
                      </button>

                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setIsBusinessDeletionWarningModalOpen(false)}
                        ref={businessDeletionWarningModalCancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          <Transition.Root show={isAddBusinessModalOpen} as={Fragment}>
            <Dialog
              as="div"
              className="fixed z-10 inset-0 overflow-y-auto"
              initialFocus={addBusinessModalCancelButtonRef}
              onClose={setIsAddBusinessModalOpen}
            >
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                  &#8203;
                </span>

                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 py-5 sm:p-6 sm:pb-5">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-lemon-200 sm:mx-0 sm:h-10 sm:w-10">
                          <PlusIcon className="h-6 w-6 text-lemon-800" aria-hidden="true" />
                        </div>

                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                            Add a businesss
                          </Dialog.Title>

                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              This will increase your monthly subscription price by £
                              {moment(showUserApiCallResponse!.user.created_at).isAfter("2023-05-08") ? "19" : "9.99"}.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <Formik
                      initialValues={{
                        name: "",
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);

                        send({
                          endpoint: CREATE_BUSINESS,
                          options: { data: { business: { name: values.name } } },
                        })
                          .then(() => {
                            if (indexBusinessesApiCallResponse!.owned_businesses.length > 0) {
                              mixpanel.track("User Created Additional Business");
                            } else {
                              mixpanel.track("User Created Trialing Business From Settings");
                            }

                            indexBusinessesApiCallRefresh();
                            showSubscriptionApiCallRefresh();

                            setIsAddBusinessModalOpen(false);
                            setTimeout(() => setSubmitting(false), 1000);
                          })
                          .catch((error) => {
                            indexBusinessesApiCallRefresh();
                            showSubscriptionApiCallRefresh();

                            setIsAddBusinessModalOpen(false);

                            setTimeout(() => {
                              setSubmitting(false);
                              setIsErrorModalOpen(true);
                            }, 1000);

                            reportError(error);
                          });
                      }}
                    >
                      {({ isSubmitting, isValid }) => (
                        <Form>
                          <div className="px-4 pb-5 sm:pl-20 sm:pr-6">
                            <Field
                              type="text"
                              name="name"
                              className="shadow-sm focus:ring-lemon focus:border-lemon block w-full sm:text-sm border-gray-300 rounded-md text-gray-800"
                              placeholder="Your business name..."
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="mt-2 text-sm font-medium text-red-500"
                            />
                          </div>

                          <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row-reverse">
                            <button
                              type="submit"
                              disabled={isSubmitting || !isValid}
                              className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-lemon text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lemon sm:ml-3 sm:w-auto sm:text-sm ${
                                isSubmitting ? "" : "hover:bg-lemon-600"
                              } ${!isValid ? "opacity-50" : ""}`}
                            >
                              {isSubmitting ? "Adding..." : "Add"}
                            </button>

                            <button
                              type="button"
                              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                              onClick={() => setIsAddBusinessModalOpen(false)}
                              ref={addBusinessModalCancelButtonRef}
                            >
                              Cancel
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          <Transition.Root show={isAccountClosureWarningModalOpen} as={Fragment}>
            <Dialog
              as="div"
              className="fixed z-10 inset-0 overflow-y-auto"
              initialFocus={accountClosureWarningModalCancelButtonRef}
              onClose={setIsAccountClosureWarningModalOpen}
            >
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                  &#8203;
                </span>

                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 py-5 sm:p-6">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                        </div>

                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                            Are you sure?
                          </Dialog.Title>

                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              This will delete all businesses, food safety records and exports associated with this
                              account irreversibly.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-400 text-base font-medium text-white hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => {
                          setIsAccountBeingClosed(true);

                          send({
                            endpoint: CREATE_USER_DEACTIVATION,
                          })
                            .then(() => {
                              logout();
                              mixpanel.track("User Closed Account");
                            })
                            .catch((error) => {
                              setIsAccountClosureWarningModalOpen(false);

                              setTimeout(() => {
                                setIsAccountBeingClosed(false);
                                setIsErrorModalOpen(true);
                              }, 1000);

                              reportError(error);
                            });
                        }}
                      >
                        {isAccountBeingClosed ? "Closing..." : "Close"}
                      </button>

                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setIsAccountClosureWarningModalOpen(false)}
                        ref={accountClosureWarningModalCancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        </>
      );
    default:
      return null;
  }
}
